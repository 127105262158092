import * as React from "react";
import Navbar from "../../Components/Navbar";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../../Client";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Table, Modal } from "flowbite-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Header from "../../Components/Header";
import Swal from "sweetalert2";
import CountryDropdown from "../../Components/CountryDropdown";

export default function Providers() {
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({});

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const countries = CountryDropdown();

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Provider added");
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // loading provider
  const { data: providers, isLoading } = useQuery({
    queryFn: () => sendGetRequest("providers", {}),
    queryKey: ["providers"],
  });

  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        doDeleteMutation(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Provider deleted", background: "#de2016" });
  };

  // deleting provider
  const providerDelete = async (id) => {
    const res = await sendPostRequest("provider/delete", {
      providerID: id,
    });
    console.log(res);
  };

  // adding providers
  const providerAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return providerAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["providers"] });
    },
  });

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return providerDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["providers"] });
      deletedNotyf();
    },
  });

  function onCloseModal() {
    setOpenModal(false);
  }

  if (!isLoading) {
    return (
      <>
        <Header />

        <section className="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
          <div className="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-80">
            <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
              <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between w-[94%] ml-[3%] mt-[3%] pb-6">
                <h3 className="text-base font-semibold text-gray-900">
                  Providers
                </h3>
                <div className="mt-3 flex sm:ml-4 sm:mt-0">
                  <button
                    type="button"
                    onClick={() => setOpenModal(true)}
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Add Provider
                  </button>
                </div>
              </div>

              <div className="overflow-x-auto p-6">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-4 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Address
                      </th>
                      <th scope="col" className="px-4 py-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {providers?.providers.map((provider) => (
                      <tr className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                        <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                          {provider.name}
                        </td>
                        <td className="px-4 py-2">
                          <div className="flex items-center text-sm font-medium text-gray-500">
                            {provider.address1}, {provider.town},{" "}
                            {provider.postcode}
                          </div>
                        </td>

                        <td className="w-32 px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <a
                            onClick={() => {
                              if (areYouSure(provider.providerID)) {
                                providerDelete(provider.providerID);
                              }
                            }}
                            className="text-red-600 hover:text-red-900"
                          >
                            <Button color="failure" size="sm">
                              Delete
                            </Button>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <Modal show={openModal} size="5xl" onClose={onCloseModal} popup>
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-3">
              <h2 className="text-lg font-bold text-gray-900 dark:text-white">
                Add Provider
              </h2>

              <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Name
                  </label>
                  <input
                    type="input"
                    id="name"
                    name="name"
                    onChange={handleChange}
                    value={inputs.name || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="contactName"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Contact Name
                  </label>
                  <input
                    type="input"
                    id="contactName"
                    name="contactName"
                    onChange={handleChange}
                    value={inputs.contactName || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>

                <div className="w-1/4 p-1">
                  <label
                    for="contactNumber"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Contact Number
                  </label>
                  <input
                    type="input"
                    id="contactNumber"
                    name="contactNumber"
                    onChange={handleChange}
                    value={inputs.contactNumber || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>

                <div className="w-1/4 p-1">
                  <label
                    for="vatNumber"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    VAT Number
                  </label>
                  <input
                    type="input"
                    id="vatNumber"
                    name="vatNumber"
                    onChange={handleChange}
                    value={inputs.vatNumber || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="address1"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Address Line 1
                  </label>
                  <input
                    type="input"
                    id="address1"
                    name="address1"
                    onChange={handleChange}
                    value={inputs.address1 || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="address2"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Address Line 2
                  </label>
                  <input
                    type="input"
                    id="address2"
                    name="address2"
                    onChange={handleChange}
                    value={inputs.address2 || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="town"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Town
                  </label>
                  <input
                    type="input"
                    id="town"
                    name="town"
                    onChange={handleChange}
                    value={inputs.town || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="w-1/4 p-1">
                  <label
                    for="county"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    County
                  </label>
                  <input
                    type="input"
                    id="county"
                    name="county"
                    onChange={handleChange}
                    value={inputs.county || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="postcode"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Postcode
                  </label>
                  <input
                    type="input"
                    id="postcode"
                    name="postcode"
                    onChange={handleChange}
                    value={inputs.postcode || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="w-1/4 p-1">
                  <label
                    for="country"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    onChange={handleChange}
                    value={inputs.country || ""}
                    required
                    className="pl-2 bg-gray-50 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value=""></option>
                    {countries.map((country) => (
                      <option value={country.code}>{country.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <button
                type="button"
                onClick={async () => {
                  try {
                    await doAddMutation({
                      path: "provider/add",
                      body: {
                        name: inputs.name,
                        contactName: inputs.contactName,
                        contactNumber: inputs.contactNumber,
                        vatNumber: inputs.vatNumber,
                        address1: inputs.address1,
                        address2: inputs.address2,
                        town: inputs.town,
                        county: inputs.county,
                        postcode: inputs.postcode,
                        country: inputs.country,
                      },
                    });

                    setOpenModal(false);
                    setInputs({});

                    navigate(`/providers`, {
                      replace: true,
                      state: { added: 1 },
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }}
                className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-slate-600 focus:outline-none dark:focus:ring-green-600"
              >
                Add Provider
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
