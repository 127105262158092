import * as React from "react";
import Navbar from "../../Components/Navbar";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../../Client";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Table, Modal } from "flowbite-react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Header from "../../Components/Header";
import Swal from "sweetalert2";
import CountryDropdown from "../../Components/CountryDropdown";
import CsvFileInput from "../../Components/CsvFileImport";
import ReactLoading from "react-loading";
import dateFormat, { masks } from "dateformat";

export default function StatementsInSummary() {
  const [openModal, setOpenModal] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);

  const [showFiltering, setShowFiltering] = useState(false);

  const queryClient = useQueryClient();

  const [csvProvider, setCsvProvider] = useState("");

  const [mainAdvisor, setMainAdvisor] = useState("");
  const [clientForename, setClientForename] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [provider, setProvider] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [dfm, setDfm] = useState("");
  const [initialSplit, setInitialSplit] = useState("");
  const [ongoingSplit, setOngoingSplit] = useState("");
  const [fumSplit, setFumSplit] = useState("");
  const [statementNumber, setStatementNumber] = useState("");
  const [statementDate, setStatementDate] = useState("");
  const [statementComms, setStatementComms] = useState("");
  const [currency2, setCurrency2] = useState("");
  const [mainAdvisorComms, setMainAdvisorComms] = useState("");
  const [introducerComms, setIntroducerComms] = useState("");
  const [fumComms, setFumComms] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");

  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");
  const [searchProvider, setSearchProvider] = useState("");

  const [fetchedData, setFetchedData] = useState([]);
  const [searched, setSearched] = useState(false);

  const navigate = useNavigate();

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Statement added");
  };

  // csv
  const [data, setData] = useState([]);
  const handleFileLoad = (csvData) => {
    setData(csvData);
  };
  // csv

  // loading statement
  const { data: StatementsInSummary, isLoading } = useQuery({
    queryFn: () => sendGetRequest("statements/summary", {}),
    queryKey: ["StatementsInSummary"],
  });

  useEffect(() => {
    setFetchedData(StatementsInSummary);
  }, [StatementsInSummary]);

  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        doDeleteMutation(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Statement deleted", background: "#de2016" });
  };

  // deleting provider
  const statementSummaryDelete = async (id) => {
    console.log(id);
    const res = await sendPostRequest("statement/summary/delete", {
      summaryID: id,
    });
    console.log(res);
  };

  // adding statement
  const statementAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return statementAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["StatementsInSummary"] });
    },
  });

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return statementSummaryDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["StatementsInSummary"] });
      deletedNotyf();
    },
  });

  function onCloseModal() {
    setOpenModal(false);
  }

  function onCloseModalUpload() {
    setOpenModalUpload(false);
  }

  // adding statement
  const csvAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddCsvMutation } = useMutation({
    mutationFn: (data) => {
      return csvAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["StatementsInSummary"] });
    },
  });

  const fetchPosts = async () => {
    // setLoading(true);

    const requestBody = {
      fromDate: searchFromDate,
      toDate: searchToDate,
      provider: searchProvider,
    };

    console.log(requestBody);

    const res = await sendPostRequest("statements/summary/search", requestBody);

    setFetchedData(res);

    // setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearched(true);
    fetchPosts();
  };

  if (!isLoading) {
    return (
      <>
        <Header />

        <section className="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
          <div className="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-80">
            <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
              <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between w-[94%] ml-[3%] mt-[3%] pb-6">
                <h3 className="text-base font-semibold text-gray-900">
                  Statements In
                </h3>
                <div className="mt-3 flex sm:ml-4 sm:mt-0">
                  <button
                    type="button"
                    onClick={() => setShowFiltering(!showFiltering)}
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    {showFiltering ? "Hide Filtering" : "Show Filtering"}
                  </button>
                  <button
                    type="button"
                    onClick={() => setOpenModalUpload(true)}
                    className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Upload Statement
                  </button>
                </div>
              </div>

              {showFiltering ? (
                <form onSubmit={handleSubmit}>
                  <div className="px-2 py-2 mt-5">
                    <div className="w-full flex flex-row mt-4 justify-center align-end">
                      <div className="w-1/6">
                        <div className="mb-5 mx-2">
                          <label
                            htmlFor="fromDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Provider
                          </label>
                          <select
                            id="provider"
                            name="provider"
                            onChange={(e) => setSearchProvider(e.target.value)}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                          >
                            <option></option>
                            <option value={"AJ Bell"}>AJ Bell</option>
                            <option value={"Ardan International"}>
                              Ardan International
                            </option>
                            <option value={"Ascentric"}>Ascentric</option>
                            <option value={"Brooks MacDonald"}>
                              Brooks MacDonald
                            </option>
                            <option value={"Capital Platform"}>
                              Capital Platform
                            </option>
                            <option value={"Friends Life"}>Friends Life</option>
                            <option value={"Friends Provident"}>
                              Friends Provident
                            </option>
                            <option value={"Generali/Utmost WW"}>
                              Generali/Utmost WW
                            </option>
                            <option value={"Momentum"}>Momentum</option>
                            <option value={"Morningstar"}>Morningstar</option>
                            <option value={"Novia"}>Novia</option>
                            <option value={"Novia Global"}>Novia Global</option>
                            <option value={"Utmost"}>Utmost</option>
                          </select>
                        </div>
                      </div>

                      <div className="w-1/6">
                        <div className="mb-5 mx-2">
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="fromDate"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              From Date
                            </label>
                            <div className="mt-2">
                              <input
                                type="date"
                                name="fromDate"
                                id="fromDate"
                                onChange={(e) =>
                                  setSearchFromDate(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/6">
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="toDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            To Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="toDate"
                              id="toDate"
                              onChange={(e) => setSearchToDate(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="w-20 ml-8 mt-8 h-10 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              <div className="overflow-x-auto p-6">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-4 py-3">
                        Date
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Provider
                      </th>

                      <th scope="col" className="px-4 py-3">
                        Uploaded By
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Statements
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Matched
                      </th>

                      <th scope="col" className="px-4 py-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <div className="inline-block min-w-full py-2 flex justify-center sm:px-6 lg:px-8">
                        <ReactLoading type={"spin"} color="#5046e5" />
                      </div>
                    ) : (
                      fetchedData?.statements?.map((statement) => (
                        <tr className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                          <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                            {/* {statement.uploaded.substring(0, 10)} */}

                            {dateFormat(statement.uploaded, "mmmm dS, yyyy")}
                          </td>
                          <td className="px-4 py-2">{statement.provider}</td>
                          <td className="px-4 py-2">
                            {statement.firstName} {statement.lastName}
                          </td>
                          <td className="px-4 py-2">
                            {statement.totalStatements}
                          </td>

                          <td className="px-4 py-2">
                            {statement.matchedStatements}
                          </td>

                          <td className="w-32 px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <a
                              href={`/statements-in/${statement.summaryID}`}
                              className="text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:ring-slate-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-slate-600 dark:hover:bg-slate-700 focus:outline-none dark:focus:ring-slate-800 rounded-lg"
                            >
                              View
                            </a>
                            <a
                              onClick={() => {
                                if (areYouSure(statement.summaryID)) {
                                  statementSummaryDelete(statement.summaryID);
                                }
                              }}
                              className="hover:cursor-pointer text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800 rounded-lg"
                            >
                              Delete
                            </a>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <Modal show={openModal} size="5xl" onClose={onCloseModal} popup>
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-3">
              <h2 className="text-lg font-bold text-gray-900 dark:text-white">
                Add Statement
              </h2>

              <div className="flex flex-row">
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="mainAdvisor"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Main Advisor
                  </label>
                  <input
                    type="input"
                    id="mainAdvisor"
                    value={mainAdvisor ? mainAdvisor : ""}
                    onChange={(e) => {
                      setMainAdvisor(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="clientForename"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Client Forename
                  </label>
                  <input
                    type="input"
                    id="clientForename"
                    value={clientForename ? clientForename : ""}
                    onChange={(e) => {
                      setClientForename(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="clientSurname"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Client Surname
                  </label>
                  <input
                    type="input"
                    id="clientSurname"
                    value={clientSurname ? clientSurname : ""}
                    onChange={(e) => {
                      setClientSurname(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="provider"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Provider
                  </label>
                  <input
                    type="input"
                    id="provider"
                    value={provider ? provider : ""}
                    onChange={(e) => {
                      setProvider(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="policyNumber"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Policy Number
                  </label>
                  <input
                    type="input"
                    id="policyNumber"
                    value={policyNumber ? policyNumber : ""}
                    onChange={(e) => {
                      setPolicyNumber(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="accountNumber"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Account Number
                  </label>
                  <input
                    type="input"
                    id="accountNumber"
                    value={accountNumber ? accountNumber : ""}
                    onChange={(e) => {
                      setAccountNumber(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="currency"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Currency
                  </label>
                  <input
                    type="input"
                    id="currency"
                    value={currency ? currency : ""}
                    onChange={(e) => {
                      setCurrency(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="dfm"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    DFM
                  </label>
                  <input
                    type="input"
                    id="dfm"
                    value={dfm ? dfm : ""}
                    onChange={(e) => {
                      setDfm(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="initialSplit"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Initial Split
                  </label>
                  <input
                    type="input"
                    id="initialSplit"
                    value={initialSplit ? initialSplit : ""}
                    onChange={(e) => {
                      setInitialSplit(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="ongoingSplit"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Ongoing Split
                  </label>
                  <input
                    type="input"
                    id="ongoingSplit"
                    value={ongoingSplit ? ongoingSplit : ""}
                    onChange={(e) => {
                      setOngoingSplit(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="fumSplit"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    FUM Split
                  </label>
                  <input
                    type="input"
                    id="fumSplit"
                    value={fumSplit ? fumSplit : ""}
                    onChange={(e) => {
                      setFumSplit(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="statementNumber"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Statement Number
                  </label>
                  <input
                    type="input"
                    id="statementNumber"
                    value={statementNumber ? statementNumber : ""}
                    onChange={(e) => {
                      setStatementNumber(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="statementComms"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Statement Comms
                  </label>
                  <input
                    type="input"
                    id="statementComms"
                    value={statementComms ? statementComms : ""}
                    onChange={(e) => {
                      setStatementComms(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="currency2"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Currency 2
                  </label>
                  <input
                    type="input"
                    id="currency2"
                    value={currency2 ? currency2 : ""}
                    onChange={(e) => {
                      setCurrency2(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="mainAdvisorComms"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Main Advisor Comms
                  </label>
                  <input
                    type="input"
                    id="mainAdvisorComms"
                    value={mainAdvisorComms ? mainAdvisorComms : ""}
                    onChange={(e) => {
                      setMainAdvisorComms(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="introducerComms"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Introducer Comms
                  </label>
                  <input
                    type="input"
                    id="introducerComms"
                    value={introducerComms ? introducerComms : ""}
                    onChange={(e) => {
                      setIntroducerComms(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="fumComms"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    FUM Comms
                  </label>
                  <input
                    type="input"
                    id="fumComms"
                    value={fumComms ? fumComms : ""}
                    onChange={(e) => {
                      setFumComms(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="invoiceDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Invoice Date
                  </label>
                  <input
                    type="date"
                    id="invoiceDate"
                    value={invoiceDate ? invoiceDate : ""}
                    onChange={(e) => {
                      setInvoiceDate(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="statementDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Statement Date
                  </label>
                  <input
                    type="date"
                    id="statementDate"
                    value={statementDate ? statementDate : ""}
                    onChange={(e) => {
                      setStatementDate(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <button
                type="button"
                onClick={async () => {
                  try {
                    await doAddMutation({
                      path: "statement/add",
                      body: {
                        mainAdvisor,
                        clientForename,
                        clientSurname,
                        provider,
                        policyNumber,
                        accountNumber,
                        currency,
                        dfm,
                        initialSplit,
                        ongoingSplit,
                        fumSplit,
                        statementNumber,
                        statementDate,
                        statementComms,
                        currency2,
                        mainAdvisorComms,
                        introducerComms,
                        fumComms,
                        invoiceDate,
                      },
                    });

                    setOpenModal(false);
                    setInputs({});
                  } catch (e) {
                    console.log(e);
                  }
                }}
                className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-slate-600 focus:outline-none dark:focus:ring-green-600"
              >
                Add Statement
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={openModalUpload}
          size="2xl"
          onClose={onCloseModalUpload}
          popup
        >
          <Modal.Header />
          <Modal.Body>
            <form>
              <div className="flex flex-row mb-6">
                <div className="w-1/3">
                  <div className="mb-5 mx-2">
                    <label
                      htmlFor="fromDate"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Provider
                    </label>

                    <select
                      id="provider"
                      name="provider"
                      onChange={(e) => setCsvProvider(e.target.value)}
                      className="mt-2 h-11 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option></option>
                      <option value={"AJ Bell"}>AJ Bell</option>
                      <option value={"Ardan International"}>
                        Ardan International
                      </option>
                      <option value={"Ascentric"}>Ascentric</option>
                      <option value={"Brooks MacDonald"}>
                        Brooks MacDonald
                      </option>
                      <option value={"Capital Platform"}>
                        Capital Platform
                      </option>
                      <option value={"Friends Life"}>Friends Life</option>
                      <option value={"Friends Provident"}>
                        Friends Provident
                      </option>
                      <option value={"Generali/Utmost WW"}>
                        Generali/Utmost WW
                      </option>
                      <option value={"Momentum"}>Momentum</option>
                      <option value={"Morningstar"}>Morningstar</option>
                      <option value={"Novia"}>Novia</option>
                      <option value={"Novia Global"}>Novia Global</option>
                      <option value={"Utmost"}>Utmost</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-row ml-4 mt-8 mb-4">
                  <CsvFileInput onFileLoad={handleFileLoad} />
                </div>
              </div>

              <div className="flex flex-row">
                {data.length > 0 ? (
                  <>
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          await doAddCsvMutation({
                            path: "statements/csv/add",
                            body: {
                              data,
                              provider: csvProvider,
                              userID: 2,
                            },
                          });

                          setOpenModalUpload(false);
                          setInputs({});
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      className="w-32 text-white bg-green-500 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium text-sm px-2 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800 ml-2"
                    >
                      Confirm
                    </button>
                    {/* <div className="mt-2">{data.length} statements to confirm</div> */}
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => console.log("No statements to confirm")}
                    className="w-32 text-white bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 ml-2"
                  >
                    Confirm
                  </button>
                )}
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
