import * as React from "react";
import Navbar from "../../Components/Navbar";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../../Client";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Table, Modal } from "flowbite-react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Swal from "sweetalert2";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte

export default function Matching() {
  const queryClient = useQueryClient();

  const [selectedSubmitted, setSelectedSubmitted] = useState(0);

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  // loading submitted
  const { data: unmatchedSubmitted, isLoading } = useQuery({
    queryFn: () => sendGetRequest("submitted/unmatched", {}),
    queryKey: ["submitted"],
  });

  // loading submitted
  const { data: unmatchedStatements, isLoadingStatements } = useQuery({
    queryFn: () => sendGetRequest("statements/unmatched", {}),
    queryKey: ["statements"],
  });

  const matchedNotyf = () => {
    notyf.success("Matched!");
  };

  const matchAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    matchedNotyf();
  };

  const { mutateAsync: doMatchMutation } = useMutation({
    mutationFn: (data) => {
      return matchAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["submitted"] });
      queryClient.invalidateQueries({ queryKey: ["statements"] });
    },
  });

  // statement skip
  const skipAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
  };

  const { mutateAsync: doSkipMutation } = useMutation({
    mutationFn: (data) => {
      return skipAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["statements"] });
    },
  });

  // submitted skip
  const rejectAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
  };

  const { mutateAsync: doRejectMutation } = useMutation({
    mutationFn: (data) => {
      return rejectAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["submitted"] });
    },
  });

  return (
    <>
      <Header />

      <section className="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
        <div className="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-80">
          <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between w-[94%] ml-[3%] mt-[3%] pb-6">
              <h3 className="text-base font-semibold text-gray-900">
                Matching
              </h3>
            </div>

            <div className="w-[96%] ml-[2%]">
              <div className="px-4 sm:px-6 lg:px-8 mt-8">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold text-gray-900">
                      Statement
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                      {unmatchedStatements?.total} unmatched
                    </p>
                  </div>
                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                      type="button"
                      onClick={async () => {
                        setSelectedSubmitted(0);

                        try {
                          await doSkipMutation({
                            path: "statement/skip",
                            body: {
                              statementID:
                                unmatchedStatements.unmatched[0].statementID,
                            },
                          });
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      className="block rounded-md bg-slate-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                    >
                      Skip
                    </button>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="w-1/6 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Provider
                              </th>
                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Advisor
                              </th>
                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Value
                              </th>
                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Statement Number
                              </th>
                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Policy Number
                              </th>
                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              ></th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {unmatchedStatements?.unmatched.map((statement) => (
                              <tr key={statement.statementID}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {statement.provider}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {statement.mainAdvisor}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {statement.initialSplit}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {statement.statementNumber}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {statement.policyNumber}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <div className="rounded-full bg-white"></div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-4 sm:px-6 lg:px-8 mt-8 mb-20">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold text-gray-900">
                      Submitted Business
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                      {unmatchedSubmitted?.total} unmatched
                    </p>
                  </div>
                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    {selectedSubmitted > 0 && (
                      <button
                        type="button"
                        onClick={async () => {
                          try {
                            await doMatchMutation({
                              path: "matching",
                              body: {
                                submittedID: selectedSubmitted,
                                statementID:
                                  unmatchedStatements.unmatched[0].statementID,
                              },
                            });
                          } catch (e) {
                            console.log(e);
                          }
                        }}
                        className={`bg-green-500 hover:bg-green-400 block rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 `}
                      >
                        Confirm
                      </button>
                    )}
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-green-50">
                            <tr>
                              <th
                                scope="col"
                                className="w-1/6 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Provider
                              </th>
                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Advisor
                              </th>
                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Value
                              </th>
                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Client Ref
                              </th>
                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Bond Platform No
                              </th>
                              <th
                                scope="col"
                                className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              ></th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {unmatchedSubmitted?.unmatched.map((submitted) => (
                              <tr
                                className={`hover:bg-green-50 `}
                                onClick={() =>
                                  setSelectedSubmitted(submitted.submittedID)
                                }
                                key={submitted.submittedID}
                              >
                                <td
                                  className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ${
                                    submitted.submittedID === selectedSubmitted
                                      ? "border-l-4 border-green-400"
                                      : ""
                                  }`}
                                >
                                  {submitted.productProvider}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {submitted.advisorFirstName}{" "}
                                  {submitted.advisorSurname}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {submitted.advisorFee}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {submitted.clientRef}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {submitted.bondPlatformNo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex flex-row">
                                  <div
                                    className={`rounded-full ${
                                      submitted.productProvider ===
                                      unmatchedStatements?.unmatched[0].provider
                                        ? "bg-green-400"
                                        : "bg-red-400"
                                    } w-4 h-4`}
                                  ></div>
                                  <div
                                    className={`rounded-full ${
                                      submitted.advisorSurname ===
                                      unmatchedStatements?.unmatched[0]
                                        .mainAdvisor
                                        ? "bg-green-400"
                                        : "bg-red-400"
                                    } w-4 h-4 ml-1`}
                                  ></div>
                                  <div
                                    className={`rounded-full ${
                                      submitted.advisorFee ===
                                      unmatchedStatements?.unmatched[0]
                                        .initialSplit
                                        ? "bg-green-400"
                                        : "bg-red-400"
                                    } w-4 h-4 ml-1`}
                                  ></div>
                                  <div
                                    className={`rounded-full ${
                                      submitted.clientRef ===
                                      unmatchedStatements?.unmatched[0]
                                        .statementNumber
                                        ? "bg-green-400"
                                        : "bg-red-400"
                                    } w-4 h-4 ml-1`}
                                  ></div>
                                  <div
                                    className={`rounded-full ${
                                      submitted.bondPlatformNo ===
                                      unmatchedStatements?.unmatched[0]
                                        .policyNumber
                                        ? "bg-green-400"
                                        : "bg-red-400"
                                    } w-4 h-4 ml-1`}
                                  ></div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
