import * as React from "react";
import Navbar from "../../Components/Navbar";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../../Client";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Table, Modal } from "flowbite-react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Swal from "sweetalert2";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { useParams } from "react-router-dom";

export default function Matched() {
  const queryClient = useQueryClient();

  const navigate = useNavigate();


  const { summaryID } = useParams();
  const { statementID } = useParams();

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  // loading matched records
  const { data: matchedRecords, isLoading } = useQuery({
    queryFn: () => sendPostRequest("matchedRecords", {statementID}),
    queryKey: ["submitted"],
  });

  console.log('matchedRecords', matchedRecords);


  const unmatchedNotyf = () => {
    notyf.success({ message: "Statement unmatched", background: "#de2016" });
  };

  const areYouSureUnmatch = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Unmatch",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        doDeleteMutationUnmatch(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const unmatchDelete = async (id) => {

    const res = await sendPostRequest("statement/unmatch", {
      statementID: id,
    });

    navigate(`/statements-in/${summaryID}`, {});

  };

  const { mutateAsync: doDeleteMutationUnmatch } = useMutation({
    mutationFn: (id) => {
      return unmatchDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["statements"] });
      unmatchedNotyf();
    },
  });

  return (
    <>
      <Header />

      <section className="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
        <div className="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-80">
          <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
              <div className="flex items-center p-8">
                <a
                  href={`/statements-in/` + summaryID}
                  className="font-bold text-gray-700"
                >
                  <span>Back</span>
                </a>
              </div>
            </div>

            <div class="overflow-x-auto p-6 flex flex-row">
              <table class="w-1/2 text-sm text-left text-gray-500 dark:text-gray-400 m-4">
                <thead class="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-4 py-3 w-1/2">
                      Statement
                    </th>
                    <th scope="col" class="px-4 py-3 w-1/2"></th>
                  </tr>
                </thead>
                <tbody className="border-2 rounded-lg">
                  {matchedRecords?.statement.map((statement) => (
                    <>
                      <tr>
                        <td class="px-4 py-2 w-1/3">Provider</td>
                        <td>{statement.provider}</td>
                      </tr>

                      <tr>
                        <td class="px-4 py-2 w-1/3">Advisor</td>
                        <td>{statement.mainAdvisor}</td>
                      </tr>

                      <tr>
                        <td class="px-4 py-2 w-1/3">Value</td>
                        <td>{statement.initialSplit}</td>
                      </tr>

                      <tr>
                        <td class="px-4 py-2 w-1/3">Statement Number</td>
                        <td>{statement.statementNumber}</td>
                      </tr>

                      <tr>
                        <td class="px-4 py-2 w-1/3">Policy Number</td>
                        <td>{statement.policyNumber}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>

              <table class="w-1/2 text-sm text-left text-gray-500 dark:text-gray-400 m-4">
                <thead class="text-xs text-gray-700 uppercase bg-green-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-4 py-3 w-1/2">
                      Submitted Business
                    </th>
                    <th scope="col" class="px-4 py-3 w-1/2"></th>
                  </tr>
                </thead>
                <tbody className="border-2 rounded-lg">
                  {matchedRecords?.submitted.map((submitted) => (
                    <>
                      <tr>
                        <td class="px-4 py-2 w-1/3">Provider</td>
                        <td>{submitted.productProvider}</td>
                      </tr>
                      <tr>
                        <td class="px-4 py-2 w-1/3">Advisor</td>
                        <td>
                          {submitted.advisorFirstName}{" "}
                          {submitted.advisorSurname}
                        </td>
                      </tr>
                      <tr>
                        <td class="px-4 py-2 w-1/3">Value</td>
                        <td>{submitted.advisorFee}</td>
                      </tr>
                      <tr>
                        <td class="px-4 py-2 w-1/3">Client Ref</td>
                        <td>{submitted.clientRef}</td>
                      </tr>
                      <tr>
                        <td class="px-4 py-2 w-1/3">Bond Platform Number</td>
                        <td>{submitted.bondPlatformNo}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="p-6 ml-6">
              <div>
                <button
                  type="button"
                  onClick={async () => {
                    if (areYouSureUnmatch(statementID)) {
                      unmatchDelete(statementID);
                    }
                  }}
                  class="mt-8 text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800 w-24"
                >
                  Unmatch
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
