import * as React from "react";
import Navbar from "../../Components/Navbar";
import { sendGetRequest, useGetRequest, sendPostRequest } from "../../Client";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Table, Modal } from "flowbite-react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Header from "../../Components/Header";
import Swal from "sweetalert2";
import CountryDropdown from "../../Components/CountryDropdown";
import CsvFileInput from "../../Components/CsvFileImport";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";

export default function StatementsIn() {
  const { summaryID } = useParams();

  const [openModal, setOpenModal] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const [csvProvider, setCsvProvider] = useState("");

  const [mainAdvisor, setMainAdvisor] = useState("");
  const [clientForename, setClientForename] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [provider, setProvider] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [dfm, setDfm] = useState("");
  const [initialSplit, setInitialSplit] = useState("");
  const [ongoingSplit, setOngoingSplit] = useState("");
  const [fumSplit, setFumSplit] = useState("");
  const [statementNumber, setStatementNumber] = useState("");
  const [statementDate, setStatementDate] = useState("");
  const [statementComms, setStatementComms] = useState("");
  const [currency2, setCurrency2] = useState("");
  const [mainAdvisorComms, setMainAdvisorComms] = useState("");
  const [introducerComms, setIntroducerComms] = useState("");
  const [fumComms, setFumComms] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");

  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");

  const [fetchedData, setFetchedData] = useState([]);
  const [searched, setSearched] = useState(false);

  const navigate = useNavigate();

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Statement added");
  };

  // csv
  const [data, setData] = useState([]);
  const handleFileLoad = (csvData) => {
    setData(csvData);
  };
  // csv

  // loading statement
  const { data: statements, isLoading } = useQuery({
    queryFn: () => sendGetRequest("statements/" + summaryID, {}),
    queryKey: ["statements"],
  });

  useEffect(() => {
    setFetchedData(statements);
  }, [statements]);

  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        doDeleteMutation(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Statement deleted", background: "#de2016" });
  };

  // deleting provider
  const statementDelete = async (id) => {
    console.log(id);
    const res = await sendPostRequest("statement/delete", {
      statementID: id,
    });
    console.log(res);
  };

  // adding statement
  const statementAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return statementAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["statements"] });
    },
  });

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return statementDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["statements"] });
      deletedNotyf();
    },
  });

  function onCloseModal() {
    setOpenModal(false);
  }

  function onCloseModalUpload() {
    setOpenModalUpload(false);
  }

  // adding statement
  const csvAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddCsvMutation } = useMutation({
    mutationFn: (data) => {
      return csvAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["statements"] });
    },
  });

  const fetchPosts = async () => {
    // setLoading(true);

    const requestBody = {
      fromDate: searchFromDate,
      toDate: searchToDate,
    };

    const res = await sendPostRequest("statements/search", requestBody);

    setFetchedData(res);

    // setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearched(true);
    fetchPosts();
  };

  if (!isLoading) {
    return (
      <>
        <Header />

        <section className="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
          <div className="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-80">
            <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
              <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
                <div className="flex items-center p-8">
                  <a
                    href="/statements-in/summary"
                    className="font-bold text-gray-700"
                  >
                    <span>Back</span>
                  </a>
                </div>
              </div>

              <div className="overflow-x-auto p-6">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-4 py-3">
                        Main Advisor
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Client Name
                      </th>

                      {/* <th scope="col" className="px-4 py-3">
                        Provider
                      </th> */}
                      <th scope="col" className="px-4 py-3">
                        Policy Number
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Account Number
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Currency
                      </th>
                      <th scope="col" className="px-4 py-3">
                        DFM
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Initial Split
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Ongoing Split
                      </th>
                      <th scope="col" className="px-4 py-3">
                        FUM Split
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Statement Number
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Statement Date
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Statement Comms
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Currency 2
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Main Advisor Comms
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Introducer Comms
                      </th>
                      <th scope="col" className="px-4 py-3">
                        FUM Comms
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Invoice Date
                      </th>

                      <th scope="col" className="px-4 py-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <div className="inline-block min-w-full py-2 flex justify-center sm:px-6 lg:px-8">
                        <ReactLoading type={"spin"} color="#5046e5" />
                      </div>
                    ) : (
                      fetchedData?.statements?.map((statement) => (
                        <>
                          <tr
                            className={
                              "border-b dark:border-gray-600 " +
                              (statement.matchedAgainst > 0
                                ? "bg-green-100"
                                : "")
                            }
                          >
                            <td className="px-4 py-4 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                              {statement.mainAdvisor}
                            </td>
                            <td className="px-4 py-2">
                              {statement.clientSurname} ,{" "}
                              {statement.clientForename}
                            </td>
                            {/* <td className="px-4 py-2">{statement.provider}</td> */}
                            <td className="px-4 py-2">
                              {statement.policyNumber}
                            </td>

                            <td className="px-4 py-2">
                              {statement.accountNumber}
                            </td>
                            <td className="px-4 py-2">{statement.currency}</td>

                            <td className="px-4 py-2">{statement.dfm}</td>
                            <td className="px-4 py-2">
                              {statement.initialSplit}
                            </td>
                            <td className="px-4 py-2">
                              {statement.ongoingSplit}
                            </td>
                            <td className="px-4 py-2">{statement.fumSplit}</td>
                            <td className="px-4 py-2">
                              {statement.statementNumber}
                            </td>
                            <td className="px-4 py-2">
                              {statement.statementDate}
                            </td>
                            <td className="px-4 py-2">
                              {statement.statementComms}
                            </td>
                            <td className="px-4 py-2">{statement.currency2}</td>
                            <td className="px-4 py-2">
                              {statement.mainAdvisorComms}
                            </td>
                            <td className="px-4 py-2">
                              {statement.introducerComms}
                            </td>
                            <td className="px-4 py-2">{statement.fumComms}</td>
                            <td className="px-4 py-2">
                              {statement.invoiceDate}
                            </td>

                            {statement.matchedAgainst > 0 ? (
                              <td className="w-32 px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {/* <a
                                  onClick={() => {
                                    if (
                                      areYouSureUnmatch(statement.statementID)
                                    ) {
                                      unmatchDelete(statement.statementID);
                                    }
                                  }}
                                >
                                  <Button color="info" size="sm">
                                    Unmatch
                                  </Button>
                                </a> */}
                                <a
                                  href={`/matched/${summaryID}/${statement.statementID}`}
                                >
                                  <Button color="info" size="sm">
                                    Details
                                  </Button>
                                </a>
                              </td>
                            ) : (
                              <td className="w-32 px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <a
                                  onClick={() => {
                                    if (areYouSure(statement.statementID)) {
                                      statementDelete(statement.statementID);
                                    }
                                  }}
                                  className="text-red-600 hover:text-red-900"
                                >
                                  <Button color="failure" size="sm">
                                    Delete
                                  </Button>
                                </a>
                              </td>
                            )}
                          </tr>
                        </>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <Modal show={openModal} size="5xl" onClose={onCloseModal} popup>
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-3">
              <h2 className="text-lg font-bold text-gray-900 dark:text-white">
                Add Statement
              </h2>

              <div className="flex flex-row">
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="mainAdvisor"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Main Advisor
                  </label>
                  <input
                    type="input"
                    id="mainAdvisor"
                    value={mainAdvisor ? mainAdvisor : ""}
                    onChange={(e) => {
                      setMainAdvisor(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="clientForename"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Client Forename
                  </label>
                  <input
                    type="input"
                    id="clientForename"
                    value={clientForename ? clientForename : ""}
                    onChange={(e) => {
                      setClientForename(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="clientSurname"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Client Surname
                  </label>
                  <input
                    type="input"
                    id="clientSurname"
                    value={clientSurname ? clientSurname : ""}
                    onChange={(e) => {
                      setClientSurname(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="provider"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Provider
                  </label>
                  <input
                    type="input"
                    id="provider"
                    value={provider ? provider : ""}
                    onChange={(e) => {
                      setProvider(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="policyNumber"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Policy Number
                  </label>
                  <input
                    type="input"
                    id="policyNumber"
                    value={policyNumber ? policyNumber : ""}
                    onChange={(e) => {
                      setPolicyNumber(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="accountNumber"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Account Number
                  </label>
                  <input
                    type="input"
                    id="accountNumber"
                    value={accountNumber ? accountNumber : ""}
                    onChange={(e) => {
                      setAccountNumber(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="currency"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Currency
                  </label>
                  <input
                    type="input"
                    id="currency"
                    value={currency ? currency : ""}
                    onChange={(e) => {
                      setCurrency(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="dfm"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    DFM
                  </label>
                  <input
                    type="input"
                    id="dfm"
                    value={dfm ? dfm : ""}
                    onChange={(e) => {
                      setDfm(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="initialSplit"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Initial Split
                  </label>
                  <input
                    type="input"
                    id="initialSplit"
                    value={initialSplit ? initialSplit : ""}
                    onChange={(e) => {
                      setInitialSplit(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="ongoingSplit"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Ongoing Split
                  </label>
                  <input
                    type="input"
                    id="ongoingSplit"
                    value={ongoingSplit ? ongoingSplit : ""}
                    onChange={(e) => {
                      setOngoingSplit(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="fumSplit"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    FUM Split
                  </label>
                  <input
                    type="input"
                    id="fumSplit"
                    value={fumSplit ? fumSplit : ""}
                    onChange={(e) => {
                      setFumSplit(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="statementNumber"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Statement Number
                  </label>
                  <input
                    type="input"
                    id="statementNumber"
                    value={statementNumber ? statementNumber : ""}
                    onChange={(e) => {
                      setStatementNumber(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="statementComms"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Statement Comms
                  </label>
                  <input
                    type="input"
                    id="statementComms"
                    value={statementComms ? statementComms : ""}
                    onChange={(e) => {
                      setStatementComms(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="currency2"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Currency 2
                  </label>
                  <input
                    type="input"
                    id="currency2"
                    value={currency2 ? currency2 : ""}
                    onChange={(e) => {
                      setCurrency2(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="mainAdvisorComms"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Main Advisor Comms
                  </label>
                  <input
                    type="input"
                    id="mainAdvisorComms"
                    value={mainAdvisorComms ? mainAdvisorComms : ""}
                    onChange={(e) => {
                      setMainAdvisorComms(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="introducerComms"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Introducer Comms
                  </label>
                  <input
                    type="input"
                    id="introducerComms"
                    value={introducerComms ? introducerComms : ""}
                    onChange={(e) => {
                      setIntroducerComms(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="fumComms"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    FUM Comms
                  </label>
                  <input
                    type="input"
                    id="fumComms"
                    value={fumComms ? fumComms : ""}
                    onChange={(e) => {
                      setFumComms(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-5 mx-2">
                  <label
                    htmlFor="invoiceDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Invoice Date
                  </label>
                  <input
                    type="date"
                    id="invoiceDate"
                    value={invoiceDate ? invoiceDate : ""}
                    onChange={(e) => {
                      setInvoiceDate(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-5 mx-2">
                  <label
                    htmlFor="statementDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Statement Date
                  </label>
                  <input
                    type="date"
                    id="statementDate"
                    value={statementDate ? statementDate : ""}
                    onChange={(e) => {
                      setStatementDate(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <button
                type="button"
                onClick={async () => {
                  try {
                    await doAddMutation({
                      path: "statement/add",
                      body: {
                        mainAdvisor,
                        clientForename,
                        clientSurname,
                        provider,
                        policyNumber,
                        accountNumber,
                        currency,
                        dfm,
                        initialSplit,
                        ongoingSplit,
                        fumSplit,
                        statementNumber,
                        statementDate,
                        statementComms,
                        currency2,
                        mainAdvisorComms,
                        introducerComms,
                        fumComms,
                        invoiceDate,
                      },
                    });

                    setOpenModal(false);
                    setInputs({});
                  } catch (e) {
                    console.log(e);
                  }
                }}
                className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-slate-600 focus:outline-none dark:focus:ring-green-600"
              >
                Add Statement
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
